// 
// user.js
// Use this to write your custom JS
//

// document.addEventListener('DOMContentLoaded', () => {
// 	const firstScrollSpyEl = document.querySelector('[data-bs-spy="scroll"]');

// 	firstScrollSpyEl.addEventListener('activate.bs.scrollspy', function (e) {
// 		console.log('scroll change:', e);
// 	});
// });


/* Page Progress Bar ======================= */
// https://codepen.io/tutsplus/pen/QWGJxPo

let processScroll = () => {
	let docElem = document.documentElement,
		docBody = document.body,
		scrollTop = docElem['scrollTop'] || docBody['scrollTop'],
		scrollBottom = (docElem['scrollHeight'] || docBody['scrollHeight']) - window.innerHeight,
		scrollPercent = scrollTop / scrollBottom * 100 + '%';

	const progressBar = document.querySelector(".progress-bar");

	progressBar && progressBar.style.setProperty("--scrollAmount", scrollPercent);
}

document.addEventListener('scroll', processScroll);

/* ============================================== */
